.table {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  width: 100vw;
  height: calc(100vh - 70px);
  overflow: hidden;
  box-sizing: border-box;
  background-color: #CBD28F;
  padding: 2em;
  z-index: 0;
}

.player-vote {
  text-align: center;
  font-size: large;
  font-weight: 500;
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  flex-basis:100%;
  background-color: #CBD28F;
  padding-left: 2em;
  padding-right: 2em;
  z-index: 1;
}

.numeric {
  flex-basis: 70%;
}

.special {
  flex-basis: 30%;
  border-top: solid 1px #A8B160;
}

.average {
  font-size: x-large;
  font-weight: 500;
  text-align: center;
  padding: 1em;
  background-color: #F2F7C7;
  border-top: #A8B160;
}

@media (min-width: 1200px) {
  .cards {
    padding-left: 5em;
    padding-right: 5em;
  }
}

@media (min-width: 800px) and (max-width: 1199px) {
  .cards {
    padding-left: 1em;
    padding-right: 1em;
  }
}


@media (max-width: 799px) {
  .table,
  .cards {
    padding: 0;
  }
}
