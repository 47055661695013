.card-table-app {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #CBD28F;
}

.content {
  height: calc(100vh - 50px); /* All except the Header */ 
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  background-color: #CBD28F;
}
