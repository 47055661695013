.login {
  margin: 3em;
  align-self: center;
  height: 80%;
  width: 50%;
  min-width: 400px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
}

.login-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0.3em 0;
}

.player,
.room,
.secret {
  padding: 1em;
  font-size: larger;
  border: solid 1px green;
  width: 50%;
  min-width: 200px;
}

.login-input .label {
  font-size: larger;
  font-weight: 600;
  margin: 1em;
}

#enter {
  width: 50%;
  margin-top:0.3em;
  padding: 1em;
  font-size: larger;
  align-self: flex-end;
}
