.player-list {
  display: flex;
  flex-direction: column;
  border-left: solid 1px #2D4A09;
  background-color: #A9C585;
  flex-basis: 30%;
  min-height: 50%;
  width: 100%;
}

.player-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 400;
  background-color: #A7C385;
  border: solid 1px #4A6D1F;
  border-bottom: none;
  box-shadow:   1px 1px 2px 0px #2D4A09,
                1px 1px 0px 1px #FAFCE6 inset;
  height: 50px;
  margin-bottom: 2px;
}

.voted {
  background-color: #83A45A;
  border: solid 1px #2F4D0B
}

@media (max-width: 1199px) {
  .player-list {
    flex-basis: 25%;
  }

  .player-name {
    font-size: 1.2rem;
  }
}

@media (max-width: 799px) {
  .player-list {
    flex-basis: 20%;
  }
  
  .player-name {
    font-size: 1rem;
  }
}
