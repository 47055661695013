.card {
  background-color: #DFBD96;
  border: 1px solid #BB9163;
  box-shadow:   1px 1px 0px 1px #966C3D,
                1px 1px 1px 1px #FFE7CD inset;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  min-width: 150px;
  max-width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  z-index: 3;
}

.cards.numeric .card {
  height: 200px;
}

.cards.special .card {
  height: 250px;
}

.card-number {
  font-size: 2rem;
  font-weight: bold;
}

.card-text {
  font-size: 1.25rem;
  font-weight: bold;
  z-index: 1;
}

.card-title {
  font-size: 1rem;
}

.card-image {
  display: block;
  max-width: 100px;
  height: auto;
}

.selected {
  box-shadow: 0px 0px 4px 2px #7B6120;
  background-color: #FFE8CF;
}

/* Large screens: FullHD and bigger */
@media (min-width: 1919px) {
  .card {
    padding: 2rem;
    font-size: 3rem;
    font-weight: 400;
  }
}

/* Short screen */
@media (max-height: 899px) {
  .cards.numeric .card {
    min-height: 50px;
    max-height: 170px;
  }

  .cards.special .card {
    min-height: 80px;
    max-height: 200px;
  }

  .cards.special .card-image {
    height: 50px;
  }
}

/* Narrow screen  */
@media (max-width: 699px) {
  .cards.numeric .card {
    min-width: 100px;
    max-width: 200px;
    min-height: 40px;
    max-height: 90px;
  }

  .cards.special .card {
    min-height: 40px;
    max-height: 130px;
  }

  .cards.special .card-image {
    height: 30px;
  }
}

/* Narrow but tall screen  */
@media (max-width: 699px) and (min-height: 900px) {
  .cards.numeric .card {
    min-height: 120px;
    max-height: 150px;
  }

  .cards.special .card {
    min-height: 150px;
    max-height: 200px;
  }

  .cards.special .card-image {
    height: 50px;
  }
}

/* Medium sized screen  */
@media (max-height: 699px) {
  .cards.numeric .card {
    min-height: 60px;
    max-height: 120px;
  }

  .cards.special .card {
    min-height: 80px;
    max-height: 140px;
  }

  .cards.special .card-text {
    height: 0.5rem;
    margin-bottom: 0.5em;
  }

  .cards.special .card-image {
    height: 30px;
  }
}

/* Minimum sized screen */
@media (max-height: 499px) {
  .cards.numeric .card {
    min-height: 40px;
    max-height: 100px;
  }

  .cards.numeric .card-number {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .cards.special .card {
    height: 80px;
  }

  .cards.special .card-text {
    height: 0.5rem;
  }

  .cards.special .card-image {
    height: 40px;
  }

  .cards.special .card-image {
    display: none;
  }
}

/* Super-narrow screen  */
@media (max-width: 499px) {
  .cards.numeric .card {
    min-width: 50px;
    max-width: 100px;
    min-height: 50px;
    max-height: 100px;
  }

  .cards.special .card {
    min-width: 70px;
    max-width: 120px;
    min-height: 70px;
    max-height: 150px;
  }

  .cards.special .card-image {
    height: 30px;
  }
}
